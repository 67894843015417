<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">Sign In</small>

            <v-text-field
              v-on:keyup.enter="login"
              v-model="loginDetails.username"
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Username"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
              :error-messages="
                getFieldValidationErrors(v$.loginDetails.username.$errors)
              "
              @blur="v$.loginDetails.username.$touch"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              v-on:keyup.enter="login"
              v-model="loginDetails.password"
              :type="showPasswordField ? 'text' : 'password'"
              @click:append="showPasswordField = !showPasswordField"
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Password"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
              :error-messages="
                getFieldValidationErrors(v$.loginDetails.password.$errors)
              "
              @blur="v$.loginDetails.password.$touch"
            >
              <template slot="prepend-inner">
                <v-icon
                  @click="showPasswordField = !showPasswordField"
                  color="#adb5bd"
                  size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>

            <v-btn
              v-on:keyup.enter="login"
              @click="login"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#5e72e4"
              :loading="submitButton.isLoading"
              >Sign In</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-space-between mt-4">
        <p
          @click="goToForgotPassword"
          class="text-light text-decoration-none btn-hover no-default-hover"
          style="cursor: pointer"
        >
          <small>Forgot password?</small>
        </p>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "login",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      loginDetails: {
        username: { required },
        password: { required },
      },
    };
  },
  data() {
    return {
      baseUrl: "",
      apiHost: "",
      checkbox: false,
      loginDetails: {
        username: "",
        password: "",
      },
      showPasswordField: false,
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    goToForgotPassword() {
      this.$router.push("/forgot-password");
    },

    loadAppConfig() {
      let configUrl = `${window.location.protocol}//${window.location.hostname}`;
      if (window.location.port) {
        configUrl = `${configUrl}:${window.location.port}`;
      }

      this.$http
        .get(`${configUrl}/config.json`)
        .then((configResponse) => {
          this.baseUrl = configResponse.data.BASE_URL;
          this.apiHost = configResponse.data.API_HOST;
        })
        .catch(() => {
          this.$notify.error(
            "An error occurred while initialising the app. Please try again later or contact support."
          );
        });
    },

    async login() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const loginObj = {
        email: this.loginDetails.username,
        password: this.loginDetails.password,
        device_name: "web",
      };

      this.$http
        .post(
          `${this.apiHost}/api/login`,
          loginObj,
          this.sessionStore.getHttpConfig
        )
        .then(async (result) => {
          this.sessionStore.setPostLogin(
            result.data.access_token,
            result.data.user_id,
            result.data.profile_image_uri,
            result.data.firstname,
            result.data.lastname,
            result.data.email,
            result.data.roles,
            result.data.status
          );

          this.sessionStore.setBaseUrl(this.baseUrl);
          this.sessionStore.setApiHost(this.apiHost);

          this.sessionStore.setHttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${result.data.access_token}`,
          });

          this.$notify.success(`Welcome, ${result.data.firstname}`);
          this.$router.push({
            name: "Dashboard",
          });
        })
        .catch((error) => {
          console.error("Login error: ", error);
          if ([401, 422].includes(error.response.status)) {
            this.$notify.error(error.response.data.message);
          } else {
            this.$notify.error(
              "An error occurred while loging in. Please try again later or contact support."
            );
          }
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.loadAppConfig();
  },
};
</script>
